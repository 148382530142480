
import Vue from 'vue';
import { LocButton } from '@localazy/components';

export default Vue.extend({
  name: 'Posts',
  components: {
    LocButton
  }
});
